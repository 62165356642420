<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company && account && right">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account', params: { cid, aid } }"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{ name: 'rights', params: { cid, aid } }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_rights') }}</a></router-link>

            <router-link v-if="rid" tag="li" :to="{ name: 'right', params: { cid, aid, rid } }"><a>{{ right.weekYear }}</a></router-link>
            <router-link v-else tag="li" :to="{ name: 'right-add', params: { cid, aid } }"><a>{{$t('right_add')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="section py-4" v-if="right">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-6-tablet is-4-desktop">
            <status-message
              :record="right"
              @click="fieldSet({ field: 'status', value: 'pending' })"
            />
            <div class="field">
              <sqr-input-number
                label="right_week_year"
                :value="right.weekYear"
                @change="fieldSet({ field: 'weekYear', value: $event })"
                :disabled="exists || disabled"
              />
            </div>
            <sqr-input-number
              class="field"
              label="right_vacations_previous"
              :value="right.vacationsPrevious"
              @change="fieldSet({ field: 'vacationsPrevious', value: $event })"
              :disabled="disabled"
            />
            <div class="field">
              <label class="label">{{ $t('right_vacations_contract') }}</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <sqr-input-number
                    class="field"
                    :value="right.vacationsByContract"
                    @change="
                      fieldSet({ field: 'vacationsByContract', value: $event })
                    "
                    :disabled="disabled"
                  />
                </div>
                <div class="control">
                  <sqr-button
                    class="control"
                    label="right_compute"
                    @click="partsOpen()"
                    :disabled="disabled"
                  />
                </div>
              </div>
            </div>
            <sqr-input-number
              class="field"
              label="right_vacations"
              :value="vacations"
              disabled
            />
            <sqr-input-textarea
              class="field"
              label="right_note"
              :value="right.note"
              @change="fieldSet({ field: 'note', value: $event })"
              :disabled="disabled"
            />
            <div class="field" v-if="isManager">
              <label class="label">{{ $t('right_status') }}</label>
              <status-buttons
                :status="right.status"
                :options="['pending', 'accepted']"
                :disabled="disabled"
                @change="fieldSet({ field: 'status', value: $event })"
              />
            </div>
            <div class="is-clearfix">
              <div class="buttons is-pulled-right">
                <sqr-button
                  label="buttons_cancel"
                  color="light"
                  @click="$router.back()"
                />
                <sqr-button
                  label="buttons_save"
                  color="primary"
                  @click="saveAndGo()"
                  :disabled="disabled"
                  :is-loading="saving"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal" :class="{ 'is-active': modal }" v-if="right">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div
            class="field border p-4 md:border-0 md:p-0"
            v-for="part in parts"
            :key="part.id"
          >
            <div class="columns">
              <div class="column">
                <sqr-input-date
                  label="right_part_from"
                  v-model="part.from"
                  @change="recompute(part)"
                />
              </div>
              <div class="column">
                <sqr-input-date
                  label="right_part_until"
                  v-model="part.until"
                  @change="recompute(part)"
                />
              </div>
              <div class="column">
                <sqr-input-number
                  label="right_part_days"
                  :value="part.days"
                  disabled
                />
              </div>
              <div class="column">
                <sqr-input-number
                  label="right_part_base"
                  v-model="part.base"
                  @change="recompute(part)"
                />
              </div>
              <div class="column">
                <sqr-input-number
                  label="right_part_vacations"
                  v-model="part.vacations"
                  disabled
                />
              </div>
              <div class="column is-narrow">
                <sqr-button
                  class="mt-8"
                  icon="trash"
                  tooltip="right_part_remove"
                  @click="partRemove(part.id)"
                />
              </div>
            </div>
          </div>
          <div class="field">
            <sqr-button icon="plus" label="right_part_add" @click="partAdd()" />
          </div>
          <sqr-input-number
            class="field"
            label="right_vacations_computed"
            :value="right.vacationsComputed"
            @change="fieldSet({ field: 'vacationsComputed', value: $event })"
            disabled
          />
          <sqr-error-note :error="saveError" />
          <div class="is-clearfix">
            <div class="buttons is-pulled-right">
              <sqr-button
                color="light"
                label="buttons_cancel"
                @click="partsCancel()"
              />
              <sqr-button
                color="primary"
                label="buttons_save"
                @click="partsSave()"
                :is-loading="saving"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="postcss" scoped>
.modal-content {
  width: 90%;
}
</style>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import account from './account';

import SqrInputDate from '@/sqrd/SqrInputDate';
import SqrInputNumber from '@/sqrd/SqrInputNumber';
import SqrInputTextarea from '@/sqrd/SqrInputTextarea';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import StatusMessage from '@/components/StatusMessage';
import StatusButtons from '@/components/StatusButtons';

export default {
  name: 'Right',
  mixins: [account],
  props: {
    rid: String,
  },
  components: {
    SqrInputDate,
    SqrInputNumber,
    SqrInputTextarea,
    SqrErrorNote,
    CompanyName,
    AccountName,
    SqrButton,
    StatusMessage,
    StatusButtons,
  },
  computed: {
    ...mapGetters('perms', ['isManager', 'isManagerRW', 'isEmployeeRW']),
    ...mapState('right', { right: 'record' }),
    ...mapState('right', [
      'id',
      'exists',
      'loading',
      'loadError',
      'saving',
      'saveError',
    ]),
    vacations() {
      return (
        Math.round(
          (this.right?.vacationsPrevious + this.right?.vacationsByContract) *
            100
        ) / 100
      );
    },
    disabled() {
      const canEditFromStatus = this.right?.status !== 'accepted';
      const canEditFromPerms =
        this.isManagerRW || (this.isEmployeeRW && this.uid === this.accountId);
      return !(canEditFromStatus && canEditFromPerms);
    },
  },
  data() {
    return {
      parts: [],
      modal: false,
    };
  },
  mounted() {
    if (this.rid) {
      this.sub({
        path: ['companies', this.cid, 'accounts', this.aid, 'rights'],
        id: this.rid,
      });
    } else {
      this.init({
        id: undefined,
        record: {
          companyId: this.cid,
          accountId: this.aid,
          weekYear: DateTime.local().weekYear,
          vacationsPrevious: 0,
          vacationsByContract: this.account?.yearSettings?.vacations,
        },
      });
    }
  },
  methods: {
    ...mapMutations('right', ['fieldSet', 'storeSet']),
    ...mapActions('right', ['init', 'sub', 'save']),
    partsOpen() {
      this.parts = this.right.parts ?? [];
      if (this.parts.length === 0) {
        this.partAdd();
      }
      this.modal = true;
    },
    recompute(part) {
      const from = DateTime.fromISO(part.from);
      const until = DateTime.fromISO(part.until);
      if (from.isValid && until.isValid) {
        part.days = from.until(until).count('day');
      } else {
        part.days = 0;
      }
      if (part.base) {
        part.vacations =
          Math.round(((part.days * part.base) / from.daysInYear) * 100) / 100;
      }
      this.fieldSet({ field: 'parts', value: this.parts });
      this.partsCompute();
    },
    partsCompute() {
      const sum = this.parts.reduce((total, part) => total + part.vacations, 0);
      const total = Math.round(sum * 100) / 100;
      this.fieldSet({ field: 'vacationsComputed', value: total });
    },
    partAdd() {
      const base = this.account?.yearSettings?.vacations ?? 0;
      const part = {
        id: Math.round(Math.random() * 100000).toString(),
        from: DateTime.local().startOf('year').toISODate(),
        until: DateTime.local().endOf('year').toISODate(),
        base,
      };
      this.recompute(part);
      this.parts.push(part);
      this.partsCompute();
    },
    partRemove(id) {
      const index = this.parts.findIndex(p => p.id === id);
      this.parts.splice(index, 1);
      this.partsCompute();
    },
    partsCancel() {
      this.parts = [];
      this.modal = false;
    },
    partsSave() {
      this.fieldSet({ field: 'parts', value: this.parts });
      this.fieldSet({
        field: 'vacationsByContract',
        value: this.right.vacationsComputed,
      });
      this.modal = false;
    },
    async saveAndGo() {
      if (!this.rid) {
        this.storeSet({
          field: 'path',
          value: ['companies', this.cid, 'accounts', this.aid, 'rights'],
        });
        this.storeSet({
          field: 'id',
          value: this.right.weekYear.toString(),
        });
      }
      this.fieldSet({ field: 'vacations', value: this.vacations });
      try {
        await this.save();
        this.$router.back();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
</script>
